.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid white;
}

.sidebar {
  float: right;
  width: $sidebar-width;
  margin-left: 20px;
  font-size: 0.9em;

  @include media-query($on-palm) {
    width: 100%;
    margin: 0;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.sidebar ul {
  margin-left: 0;
}

.sidebar li {
  list-style-type: none;
  margin-left: 0.7em;
}

.also.large {
  @include media-query($on-palm) {
    display: none;
  }
}
.also.small {
  display: none;
  @include media-query($on-palm) {
    display: inline;
  }
}

.headshot {
  width: $sidebar-width;
  height: $sidebar-width;
  margin-bottom: 1em;

  @include media-query($on-palm) {
    float: left;
    margin: 0 1em 0 80px;
    width: 64px;
    height: 64px;
  }
}

.rssicon {
  height: 1em;
  width: 1em;
  vertical-align: middle;
}

.post {
  margin-right: $sidebar-width;
  @include media-query($on-palm) {
    margin-right: 0;
  }
}

.post h2 {
  margin-bottom: 0;
}

.post.older .post-link {
  font-size: 20px;
}

.post-list > li.post.older {
  margin-bottom: 1em;
}

.post:after {
  content: "";
  display: table;
  clear: left;
}

.post-meta {
  display: block;
  margin-bottom: 0.3em;
}

img.thumb {
  width: 40%;
  float: left;
  margin-right: 1em;
  margin-bottom: 0.7em;

  @include media-query($on-palm) {
    width: 100%;
    display: block;
    float: none;
    margin: 0;
  }
}

.home img.thumb {
  width: 25%;
  @include media-query($on-palm) {
    width: 100%;
  }
}

.rellinks .rellink {
  display: flex;
  align-items: center;
  font-style: italic;
  margin-bottom: 0.5em;
  text-align: right;
  a {
    white-space: nowrap;
  }
  &.previous {
    float: left;
    margin-right: 0.25em;
    div {
      align-items: left;
    }
  }
  &.next {
    float: right;
    margin-left: 0.25em;
    div {
      align-content: right;
    }
  }
}
